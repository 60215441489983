.topbar {
  height: 90px;
  background: white !important;
  position: sticky;
  /* position: fixed; */
  width: 100%;
  top: 0;
  z-index: 100;
  box-shadow: 0px 2px 4px -1px rgb(241 234 234 / 20%),
    0px 4px 5px 0px rgb(239 231 231 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
.topbarWrapper {
  display: flex;
  height: 100%;
  padding: 0px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}
.topbarWrapper h6 {
  font-weight: 400;
  font-size: 23px;
  color: #EF5B0C;
  margin-bottom: 0;
}
.search-bar {
  display: flex;
  flex: 2 1;
  align-items: center;
  height: 48px;
  min-width: 170px;
  max-width: 335px;
  padding: 0 18px;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
}
.search_bar_small {
  display: none;
}
.search-bar input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  padding-left: 20px;
  padding-right: 50px;
}
.search_icon {
  position: absolute;
  right: 18px;
}
.profile p {
  color: #be3f45;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  /* text-align: center; */
}
.profile h4 {
  font-weight: 400;
  font-size: 19px;
  color: #EF5B0C;
}
.mobile-profile {
  display: none;
}
.large-profile {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-large {
  display: none;
}



/* -------------------------------------menu book--------------------------- */
.orange-btn{
  padding: 10px 20px;
  background: #FFF5EF 0% 0% no-repeat padding-box;
border: 1px solid #EF5B0C;
border-radius: 6px;
color: #EF5B0C;
font-size: 14px;
}

.orange-btn:hover{
  color: #FFF5EF ;
  background: #EF5B0C 0% 0% no-repeat padding-box;
}
/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .topbarIconcontainer {
    margin-right: 20px;
  }
  .avatarContainer {
    margin-right: 0;
  }
  /* .search-bar {
    display: none;
  }
  .search_bar_small {
    display: block;
 
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 9px;
    width: 40px;
  } */
  .topbarWrapper h6 {
    display: none;
  }
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .pro-sidebar {
    min-width: 80px !important;
  }
  .main-content {
    margin-left: 80px;
  }
  .avatarName {
    display: none;
  }
  .collaps-profile {
    display: block;
  }
  .profile h4 {
    display: none;
  }
  .search-bar {
    width: 50%;
  }
  .search-bar input {
    width: 80%;
    height: 100%;
    outline: none;
    border: none;
    padding-left: 0px;
    padding-right: 6px;
  }
  /* .search-bar {
    display: none;
  }
  .search_bar_small {
    display: block;
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 9px;
    width: 40px;
  } */
  .large-profile {
    display: none !important;
  }
  .topbarWrapper {
    padding: 0 15px;
  }
  .topbarWrapper h6 {
    display: none;
  }
}

/* // Medium /devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .pro-sidebar {
    min-width: 80px !important;
  }
  .main-content {
    margin-left: 80px;
  }
  .collaps-profile {
    display: none;
  }
  .topRight {
    display: none;
  }
  .profile h4 {
    display: none;
  }
  .topbarWrapper h6 {
    display: none;
  }

  .search-bar {
    width: 50%;
  }
  .search-bar input {
    width: 80%;
    height: 100%;
    outline: none;
    border: none;
    padding-left: 0px;
    padding-right: 6px;
  }
  .mobile-profile {
    display: block;
  }
  .large-profile {
    display: none !important;
  }
}

@media (max-width: 1024.98px) {
  .search-bar input {
    width: 80%;
    height: 100%;
    outline: none;
    border: none;
    padding-left: 20px;
    padding-right: 50px;
  }
  .profile h4 {
    display: none;
  }
  .topbarWrapper h6 {
    display: none;
  }
  .large-profile {
    display: none !important;
  }
  .mobile-profile {
    display: block;
  }

  .search-bar {
    width: 50%;
  }
  .search-bar input {
    width: 80%;
    height: 100%;
    outline: none;
    border: none;
    padding-left: 0px;
    padding-right: 6px;
  }
  /* .search_bar_small {
    display: block;
   
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 9px;
    width: 40px;
  } */
  .menu-large {
    display: block;
  }
}
/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .search-bar input {
    width: 80%;
    height: 100%;
    outline: none;
    border: none;
    /* padding-left: 20px;
    padding-right: 50px; */
  }
  .profile h4 {
    display: none;
  }
  .menu-large {
    display: none;
  }
}
