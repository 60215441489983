.food-cat .highlightOption,.food-cat .selected,.food-cat .option:hover{
    background-color: white !important;
    color: #EF5B0C !important;
    font-size: 17px !important;
}
.food-cat .chip{
    background-color: white !important;
    color: #EF5B0C !important;
    margin-bottom: 5px !important;
    margin-right: 5px !important;
    padding: 4px 1px !important;
    border-radius: 0px;
    font-size: 17px!important;
}
.option input[type="checkbox"]:checked {
    background-color: white !important;
    color: #EF5B0C !important;
}
.f2 .multiSelectContainer{
    background-color: white !important;
    border-radius: 12px;
}
.f2 .searchWrapper{
    padding-left: 5px !important;
}
.multiSelectContainer input{
    padding-left: 7px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}