@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}
.login_content h2 {
    font-size: 58px;
    font-weight: 600;
    color: #EF5B0C;
    /* color: ; */
    font-family: 'Inter', sans-serif;
}

.signin_form{
    padding-right: 140px;
}
.signin_form,
.form-control{
    font-weight: 600!important;
    padding-left: 0!important;
}
.signin_form
.form-check-input{
    margin-top: 3px !important;
    border: 2px solid #828282 !important;
    border-radius: 4px !important;
}
.signin_form,
.form-label{
    font-weight: 400;
    font-size: 19px;
    line-height: 21px;
    letter-spacing: 0.0015em;
    color: #EF5B0C;
}
.form-control:focus{
    border-color:transparent !important;
    box-shadow: none !important;
    background: transparent !important;
}
.signin_btn button{
    width: 330px;
    height: 55px;
    background: #EF5B0C;
    border-radius: 30px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #FFFFFF;
}
.forget_pass{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0025em;
    color: #EF5B0C !important;
    text-decoration: none;
}
.remember_me{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0025em;
    color: #EF5B0C;
}
.sign_up_contnet p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 21px;
    letter-spacing: 0.0015em;
    color: #EF5B0C;
}
.sign_up_contnet p a{
    text-decoration: none;
    color: #2F80ED;
}
.right_img img{
    position: absolute;
    top: 20%;
    right: 8%;
}

@media only screen and (max-width: 768px) {
    .signin_form{
        padding-right: 0;
    }
    .login_content h2 {
        font-size: 30px;
    }
    .right_img img {
        position: relative;
        top: 0;
        right:0;
    }
    .img_one {
        margin-left: 18px;
        margin-top: 10px;
    }

    }

@media only screen and (min-width: 768px) and (max-width: 992px) {

}

@media only screen and (min-width: 992px) and (max-width: 1000px) {

}

@media only screen and (min-width: 999px) and (max-width: 1200px) {

}

@media only screen and (min-width: 1201px) and (max-width: 1535px) {

}


@media only screen and (min-width: 900px) {

}

@media (max-height: 700px) and (min-width: 993px) {

}
