* {
  font-family: "Open Sans", sans-serif;
}
.sidebar {
  height: 100%;
  border-radius: 0px 30px 30px 0px;
  position: fixed;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
}
.logo {
  margin-bottom: 45px;
  margin-top: 45px;
  text-align: center;

}
.logo img {
  height: 32px;
}
.userNotificaton2 {
  height: 18px;
  width: 24px;
  font-size: 11px;
  position: absolute;
  background-color: #ef5b0c;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 3px; */
  border-radius: 18%;
  z-index: 100;
  top: 17px;
  /* right: 25px; */
  left: 160px;
}
.sidebarWrapper {
  /* margin-top: 25px; */
  font-weight: 600;
  font-size: 19px;
  /* line-height: 24px; */

  color: #e0e0e0;
}
.sidebarMenu {
  margin-bottom: 10px;
  font-size: 18px;
}
.sidebarTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  margin: 5px;
  border-radius: 5px;
}
.sidebarContainer {
  display: flex;

  align-items: center;
  padding: 0 5px;
  margin: 5px;
  border-radius: 5px;
}
.sidebarTitle {
  font-size: 18px;
  cursor: pointer;
  margin-top: 9px;
  /* margin-left: 5px; */
}

.sidebarList {
  list-style: none;
  padding: 5px;
  cursor: pointer;
}
.sidebarListItem {
  /* width: 90px; */
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  align-items: center;
  border-radius: 5px;
  display: flex;
  /* justify-content: space-between; */
}

.listDecoration {
  text-decoration: none;
}

.closemenu {
  cursor: pointer;
  display: flex;
}
.sidebarTitleContainer a {
  margin-top: 0;
}

.main-content.collapsed {
  width: 100px !important;
}

.pro-sidebar > .pro-sidebar-inner {
  background-color: #34425a !important;
  height: 100vh;

  position: fixed;
  position: -webkit-sticky;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:active {
  color: rgb(239 233 233) !important;
}
.pro-sidebar {
  color: #34425a !important;
  width: 240px !important;
  min-width: 240px !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  background-color: #34425a;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 8px 12px 10px 15px !important;
}
.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: transparent !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  /* background-color: #d9d9d9; */
  color: white;
  border-radius: 8px;
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  background-color: #e0e0e0 !important;
}
.pro-sidebar.collapsed {
  width: 80px !important;
  min-width: 80px !important;
}
.pro-sidebar .pro-menu a {
  color: #a1a1a1 !important;
}

/* li.pro-menu-item.active {
  background-color: #d9d9d9;
  border-radius: 4px;
} */
li.pro-sub-menu.pro-menu-item.active {
  background-color: #fff5ef;
  border-radius: 4px;
}
li.pro-menu-item.active a {
  color: #fff5ef !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:active,
.pro-sidebar .pro-menu a:hover {
  color: #fff5ef !important;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  > .pro-inner-item
  > .pro-arrow-wrapper
  .pro-arrow {
  /* border-color: black !important;
  border-width: 0 1px 1px 0 !important;
  padding: 3.5px !important; */
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  font-weight: 600;
  font-size: 14px;
  color: FFF5EF !important;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-left: 27px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
/* .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 8px 5px 8px 10px !important;
} */
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:active {
  background-color: #d9d9d9 !important;
  border-radius: 4px;
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu.active {
  background-color: #d9d9d9 !important;
}

/* .pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 15px 12px 15px 15px !important;
} */
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  margin-bottom: none !important;
}

.pro-sidebar .pro-menu .pro-menu-item {
  /* margin: 8px 10px; */
  border-radius: 8px;
}

.noti_box {
  position: relative;
}
.userNotificaton {
  height: 18px;
  width: 18px;
  font-size: 11px;
  position: absolute;
  background-color: #ef5b0c;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 3px; */
  border-radius: 50%;
  z-index: 100;
  top: -1px;
  /* right: 25px; */
  left: 2px;
}
.css-1q1u3t4-MuiTableRow-root{
  background-color: white;
}
.css-1nhy3ol-MuiPaper-root-MuiTableContainer-root{
  border-collapse: separate !important;
  border-spacing: 0 15px !important;
}
.css-xn82ks-MuiTable-root{
  background-color: transparent !important;
}
.css-1nhy3ol-MuiPaper-root-MuiTableContainer-root{
  background-color: transparent !important; 
}


.css-z3r0a0-MuiPaper-root-MuiTableContainer-root{
  background-color: transparent !important; 
}
.css-y8ay40-MuiTableCell-root , .css-dwuj3p-MuiTableCell-root, .css-8coetn, .css-nw3mfz{
  font-weight: bold !important;
  font-family: 'Inter', sans-serif !important;
}
@media (max-width: 575.98px) {
  .pro-sidebar {
    width: 80px !important;
    min-width: 80px !important;
  }
}

@media (max-width: 767.98px) {
  .pro-sidebar {
    width: 80px !important;
    min-width: 80px !important;
  }
}

@media (max-width: 991.98px) {
  .pro-sidebar {
    width: 80px !important;
    min-width: 80px !important;
  }
}

@media (max-width: 1199.98px) {
}
