.logo-file {
  width: 155px;
  /* height: 36px; */
  padding: 8px;
  /* UI Properties */
  background: #c9c9c9 0% 0% no-repeat padding-box;
  border: 1px solid #313131;
  border-radius: 6px;
  opacity: 1;
  color: #2d2d2d;
  position: relative;
  cursor: pointer;
  justify-content: center;
  display: flex;
}
.logo-file p {
  margin: auto 0;
}
.logo-file input {
  z-index: 2222;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
}
.logo-file span {
  font: normal normal 300 15px/24px Open Sans;
  letter-spacing: 0px;
  color: #2d2d2d;
  opacity: 1;
  font-weight: 500;
}
.save-btn {
  width: 150px;
  height: 56px;
  /* UI Properties */
  background: #ef5b0c 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  margin: auto;
  border: none;
  outline: none;
  color: aliceblue;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
}
.save-btn:hover {
  color: #ef5b0c;
  border: 1px solid #ef5b0c;
  transition: 0.3s all ease;
  background: white;
}
.category-add .add {
  height: 55px;
  /* UI Properties */
  background: #a1a1a1 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  outline: none;
  border: none;
  color: white;
  margin: auto;
  padding: 10px 30px;
}
.category-tab div:last-child {
  border-radius: 0 0 12px 12px;
  border-bottom: 0px;
}
.category-tab .div {
  border-bottom: 0.4px solid #c2c2c2;
}
.add-new {
  width: 110px;
  height: 46px;
  /* UI Properties */
  background: #fff5ef 0% 0% no-repeat padding-box;
  border: 1px solid #ef5b0c;
  border-radius: 8px;
  opacity: 1;
  color: #a1a1a1;
  font-size: 16px;
  font-weight: 500;
}
.add-new:hover {
  background-color: #ef5b0c;
  transition: 0.4s all ease;
  color: white;
}
/* website */
.website-table thead tr th {
  font: normal normal normal 14px/27px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 15px;
  font-weight: normal;
}
.website-table thead {
  border-top-left-radius: 10px !important;
}
.website-table thead tr th:first-child {
  border-top-left-radius: 10px !important;
}
.website .name {
  font: normal normal bold 14px/24px Open Sans;

  color: #ef5b0c;
  opacity: 1;
}
.website .slug {
  font: normal normal 600 14px/20px Open Sans;

  color: #7a7a7a;
  opacity: 1;
}
.website .select select {
  border: none;
  outline: none;
  width: 130px;
  height: 26px;
  background-color: #f3f3f3;
  border-radius: 12px;
}
.testimonial .css-1ex1afd-MuiTableCell-root {
  border-bottom: 0px !important;
  margin-top: 15px;
}
.testimonial .css-1yhpg23-MuiTableCell-root {
  border-bottom: 0px !important;
}
.inputs-modal p {
  font: normal normal normal 15px/17px Open Sans;
  letter-spacing: 0px;
  color: #515151;
  opacity: 1;
  margin: 15px 0px;
}
.inputs-modal input ,.inputs-modal select {
  height: 55px !important;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 12px;
  opacity: 1;
  padding: 0px 15px;

}
 .inputs-modal textarea{
 
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 12px;
  opacity: 1;
  padding: 0px 15px;

}
.inputs-modal input[type='textarea']{
  height: 100px !important;
}
.inputs-modal input:focus ,.inputs-modal select:focus{
  border: 1px solid #ef5b0c !important;
  outline: none;
}
