.notification_main {
  /* padding: 0px 36px 30px 36px; */
  /* margin-top: -15px !important; */
}
.notification-box {
  cursor: pointer;
}
.cross:hover {
  background-color: black;
  transition: 0.4s all ease;
}
.box-div {
  padding: 5px;
  border-radius: 50%;
  background-color: #ffebee;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cross {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8bbc2;
  margin: 0 8px;
}
@media only screen and (max-width: 820px) {
  .notification_main {
    /* padding: 0px ; */
    padding: 0px 25px 30px 25px;
  }
}
@media only screen and (max-width: 768px) {
  .notification_main {
    /* padding: 0px 22px; */

    padding: 0px 22px 30px 22px;
  }
  .server .btn-save {
    padding: 12px 30px;
  }
  .input-head .server-title {
    font-size: 20px;
  }
  .time-notification {
    margin-left: -4px !important;
  }
}
@media only screen and (max-width: 576px) {
  .notification_main {
    padding: 0px 15px 30px 15px;
  }
  .server .btn-save {
    padding: 12px 25px;
  }
}
@media only screen and (max-width: 450px) {
  .notification_main {
    padding: 0px 8px 20px 8px;
  }
}

.server .btn-save {
  height: 55px;
  /* UI Properties */
  background: #ef5b0c 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  border: none;
  outline: none;
  padding: 12px 40px;
  color: white;
}
.input-head .server-title {
  font: normal normal 600 20px/17px Open Sans;
  letter-spacing: 0px;
  color: #515151;
  opacity: 1;
  font-size: 26px;
}
.time-to-date {
  height: 55px;
  border: 1px solid #c2c2c2;
  border-radius: 12px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
}
.time-to-date input {
  border: none;
  outline: none;
}
.time-to-date .divider {
  margin: 7px 10px;
  padding: 4px 0px;
}
.time-to-date .divider div {
  width: 1px;
  height: 35px;
  background-color: #515151;
}
.time-notification {
  margin-left: -10px !important;
}
.done-btn {
  height: 55px;
  /* UI Properties */
  background: #ef5b0c 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  padding: 12px 50px;
  border: none;
  outline: none;
  color: white;

}
.done-btn:hover{
  background: white;
  border: 1px solid #ef5b0c;
  color: #ef5b0c;
  transition: .4s all ease;
}
