.image-td img {
  border: 1px solid #707070;
  border-radius: 13px;
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.client_td h6 {
  color: #ef5b0c;
  font-weight: bold;
  font-size: 14px;
}
.client_td p {
  color: #7a7a7a;
margin-bottom: 0;
  font-size: 12px;
}
.placement_td select {
  cursor: pointer;
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  padding: 5px 10px;
  color: #d8d8d8;
}
.yellow-btn {
  background: #eede00;
  border-radius: 12px;
  border: none;
  font-size: 12px;
  padding: 8px 18px;
}
.green-btn {
  background: #6fc141;
  color: #ffffff;
  border-radius: 12px;
  border: none;
  font-size: 12px;
  padding: 8px 18px;
}
.red-btn {
  background: red;
  color: #ffffff;
  border-radius: 12px;
  border: none;
  font-size: 12px;
  padding: 8px 18px;
}
.client_td img{
  border-radius: 50%;
  width: 33px;
height: 33px;
}
