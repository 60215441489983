.shipping-address-section {
  /* padding: 20px; */
  padding-top: 0;
  position: relative;
}
.map-input{
  display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.shipping-address-section .actions {
  display: flex;
  justify-content: flex-end;
}
.shipping-address-section .cards-section {
  margin-bottom: 15px;
}
.shipping-address-section .cards-section .address-card {
  height: 100%;
}
.shipping-address-section .cards-section .address-card .ant-card-body {
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.shipping-address-section .cards-section .address-card .ant-card-body .upper {
  display: flex;
  padding: 15px;
}
@media screen and (max-width: 860px) {
  .shipping-address-section .cards-section .address-card .ant-card-body .upper {
    display: block;
 }
}
.shipping-address-section .cards-section .address-card .ant-card-body .upper .ad-na-section {
  margin-left: 15px;
}
@media screen and (max-width: 860px) {
  .shipping-address-section .cards-section .address-card .ant-card-body .upper .ad-na-section {
    margin-left: 0;
 }
}
.shipping-address-section .cards-section .address-card .ant-card-body .upper .ad-na-section .name {
  font-weight: 500;
  margin-bottom: 10px;
}
.shipping-address-section .cards-section .address-card .ant-card-body .lower {
  display: flex;
  border-top: 1px solid #eee;
  margin-top: 15px;
  padding: 8px 15px;
}
.shipping-address-section .cards-section .address-card .ant-card-body .lower .edit {
  flex: 1;
  text-align: center;
  border-right: 1px solid #eee;
  cursor: pointer;
}
.shipping-address-section .cards-section .address-card .ant-card-body .lower .delete {
  color: #ee3124;
  flex: 1;
  text-align: center;
  cursor: pointer;
}
.shipping-address-section .ant-form-item {
  margin-bottom: 20px;
}
.shipping-address-section .ant-form-item .ant-form-item-label {
  text-align: left;
  width: 150px;
}
@media screen and (max-width: 500px) {
  .shipping-address-section .ant-row {
    min-width: 100%;
 }
  .shipping-address-section .ant-row .ant-col {
    min-width: 100%;
 }
  .shipping-address-section .ant-row .ant-col .address-card {
    min-width: 100%;
 }
}
.map {
  position: relative;
  overflow: hidden;
  height: 400px;
  margin-bottom: 30px;
}
