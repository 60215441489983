/* @import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css"; */
/* @import '../../node_modules/@syncfusion/ej2/material.css'; */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
}
.topContainer {
  display: flex;
}
body {
  height: 100%;
  background-color: #f5f5f5 !important;
}
.sidebar {
  /* width: 345px; */
  position: fixed;
  height: 100%;
  border-radius: 0px 30px 30px 0px;
  left: 0;
  top: 0;
  /* background-color: #015642; */
}
.main-content {
  margin-left: 240px;
}
.main-content-collapsed {
  margin-left: 80px;
}

/* ::-webkit-scrollbar {
  width: .5em;
 
}
::-webkit-scrollbar-button {
  background: #ccc
}
::-webkit-scrollbar-track-piece {
  background: #bbb8b8
}
::-webkit-scrollbar-thumb {
  background: #eee
} */
