.review_select p{
    color: #1E3A56;
    font-size: 14px;
    margin-bottom: 0;
}
.comment_td h6{
    color: #EF5B0C;
    font-weight: bold;
    font-size: 14px;
}
.css-1ygcj2i-MuiTableCell-root {
    font-weight: 600 !important;
    font-size: 0.885rem;
  }
.css-1bigob2 {
    font-weight: 600 !important;
    font-size: 0.885rem;
  }
  .active>.page-link, .page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: whitesmoke !important;
    border-color:#EF5B0C !important;
}
.css-g9ohqm-MuiTable-root{
    border-collapse: separate !important;
    border-spacing: 0 15px !important;
}
.css-quj9j6-MuiTable-root{
    border-collapse: separate !important;
    border-spacing: 0 15px !important;
}
.css-18qulp9{
    border-collapse: separate !important;
    border-spacing: 0 15px !important;
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root{
    background-color: transparent !important;
}
.css-13xy2my{
    background-color: transparent !important;
}